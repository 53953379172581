import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
// import MockService from "./core/mock/mock.service";
// import { VERIFY_AUTH } from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueSweetalert2 from "vue-sweetalert2";
import ZoomOnHover from "vue-zoom-on-hover";
import vSelect from "vue-select";
import VueSmoothScroll from "vue2-smooth-scroll";
import DisableAutocomplete from "@aacassandra/vue-disable-autocomplete";

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
// import 'vue-select/dist/vue-select.css';

import JwtService from "@/core/services/jwt.service";

// API service init
ApiService.init();
Vue.use(VueSmoothScroll, {
  updateHistory: false
});
Vue.use(CKEditor);
Vue.use(VueSweetalert2);
Vue.use(ZoomOnHover);
Vue.use(DisableAutocomplete);

Vue.component("vue-select", vSelect);
window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

Vue.prototype.baseAppPath =
  process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_URL : "";
Vue.prototype.questionType = [
  {
    id: 0,
    type: "section",
    name: "Section"
  },
  {
    id: 1,
    type: "essay",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/essay-icon.svg`,
    name: "Essay"
  },
  {
    id: 2,
    type: "multiple choice",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/multiple-choice-icon.png`,
    name: "Pilihan Ganda"
  },
  {
    id: 3,
    type: "checkbox",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/checklist-icon.svg`,
    name: "Checklist"
  },
  {
    id: 4,
    type: "recording",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/image-icon.svg`,
    name: "Recording"
  },
  {
    id: 5,
    type: "image",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/image-icon.svg`,
    name: "Gambar"
  },
  {
    id: 6,
    type: "matrix checkbox",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/matrix-multiple-icon.png`,
    name: "Matrix Multiple"
  },
  {
    id: 7,
    type: "matrix radio",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/matrix-single-icon.png`,
    name: "Matrix Single"
  },
  {
    id: 8,
    type: "matrix isian",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/matrix-essay-icon.svg`,
    name: "Matrix Isian"
  },
  {
    id: 9,
    type: "general file",
    icon: `${(Vue.prototype.baseAppPath =
      process.env.NODE_ENV === "production"
        ? process.env.VUE_APP_BASE_URL
        : "")}/media/file-icon.svg`,
    name: "File"
  }
];

new ClipboardJS(".btn");

// Remove this to disable mock API
// MockService.init();

const no_auth_pages = ["/auth/login", "/auth/register"];

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  if (!JwtService.getToken() && !no_auth_pages.includes(to?.path)) {
    router.replace({ name: "login" });
  }

  next();

  if (from?.name == "katakota" && to?.name == "katakotadetail") {
    localStorage.lastKataKotaScrollPosY = window.scrollY;
  }

  // Scroll page to top on every route change
  if (to?.name == "katakota" && from?.name == "katakotadetail") {
    setTimeout(() => {
      window.scrollTo(0, localStorage.lastKataKotaScrollPosY || 0);
    }, 100);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
