import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setToken";
export const SET_USER_INFO = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(async (resolve, reject) => {
      let err = false;

      for (let i = 0; i < credentials.apps.length; i++) {
        if (err) break;

        let url = null;

        switch (credentials.apps[i].name) {
          case "program":
            url = `${process.env.VUE_APP_PROGRAM_URL}/api/login`;
            break;
          // case "elearning":
          //   url = `${process.env.VUE_APP_ELEARNING_URL}/api/login`;
          //   break;

          default:
            url = "oauth/token";
            break;
        }

        await ApiService.post(url, {
          ...credentials,
          client_id: credentials.apps[i].client_id,
          client_secret: credentials.apps[i].client_secret
        })
          .then(({ data }) => {
            JwtService.saveToken(data.access_token, credentials.apps[i].name);
            context.commit(SET_AUTH, data);
            if (data?.errors) {
              err = true;
            }
          })
          .catch(({ response }) => {
            err = true;
            context.commit(SET_ERROR, response?.data?.message);
            reject(response);
          });
      }

      ApiService.setHeader();
      await ApiService.get("api/user")
        .then(({ data }) => {
          if (data?.roles?.filter(el => el.id == 1)?.length > 0) {
            // ApiService.post(`${process.env.VUE_APP_ELEARNING_URL}/api/login`, {
            //   email: data?.email,
            //   name: data?.name,
            //   sso_token: JwtService.getToken("elearning"),
            //   sso_id: data?.id,
            //   list_api: JSON.stringify(data?.roles)
            // }).then(_data => {
            //   if (_data?.status == 200 || _data?.status == 201) {
            //     context.commit(SET_USER_INFO, data);
            //     resolve(data);
            //   }
            // });
          } else {
            context.commit(SET_ERROR, { message: "Email atau password salah" });
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response?.data?.message);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    // if (JwtService.getToken()) {
    ApiService.setHeader();
    ApiService.get("verify")
      .then(({ data }) => {
        context.commit(SET_AUTH, data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response?.data?.errors);
      });
    // } else {
    //   context.commit(PURGE_AUTH);
    // }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, token) {
    state.errors = [];
    JwtService.saveToken(token.access_token);
  },
  [SET_USER_INFO](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = [];
    JwtService.saveUserInfo(JSON.stringify(user));
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
    JwtService.destroyUserInfo();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
