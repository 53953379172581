const ID_TOKEN_KEY = "token";
const USER_INFO_KEY = "user_info";

export const getToken = app => {
  return window.localStorage.getItem(app ? `token_${app}` : ID_TOKEN_KEY);
};

export const saveToken = (token, app) => {
  window.localStorage.setItem(app ? `token_${app}` : ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.clear();
};

export const getUserInfo = () => {
  return window.localStorage.getItem(USER_INFO_KEY);
};

export const saveUserInfo = user_info => {
  window.localStorage.setItem(USER_INFO_KEY, user_info);
};

export const destroyUserInfo = () => {
  window.localStorage.removeItem(USER_INFO_KEY);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getUserInfo,
  saveUserInfo,
  destroyUserInfo
};
