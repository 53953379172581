import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import { BToast } from "bootstrap-vue";
import { LOGOUT } from "@/core/services/store/auth.module";
import store from "@/core/services/store";
import router from "../../router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  baseUrl: process.env.VUE_APP_SERVER_URL,
  elearningUrl: process.env.VUE_APP_ELEARNING_URL,
  surveyUrl: process.env.VUE_APP_SURVEY_URL,
  elibraryUrl: process.env.VUE_APP_ELIBRARY_URL,
  programUrl: process.env.VUE_APP_PROGRAM_URL,
  visualisasiUrl: process.env.VUE_APP_VISUALISASI_URL,
  sistemSehatUrl: process.env.VUE_APP_SISTEM_SEHAT_URL,
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = this.baseUrl;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  setAuthorizationHeader(token) {
    Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  // get(resource, slug = "") {
  //   return Vue.axios.get(`${resource}/${slug}`).catch(error => {
  //     // console.log(error);
  //     throw new Error(`[KT] ApiService ${error}`);
  //   });
  // },
  get(resource, base_url) {
    if (base_url == "elearning") {
      Vue.axios.defaults.baseURL = this.elearningUrl;
    } else if (base_url == "survey") {
      Vue.axios.defaults.baseURL = this.surveyUrl;
    } else if (base_url == "program") {
      Vue.axios.defaults.baseURL = this.programUrl;
    } else if (base_url == "elibrary") {
      Vue.axios.defaults.baseURL = this.elibraryUrl;
    } else if (base_url == "visualisasi") {
      Vue.axios.defaults.baseURL = this.visualisasiUrl;
    } else if (base_url == "sistemsehat") {
      Vue.axios.defaults.baseURL = this.sistemSehatUrl;
    } else {
      Vue.axios.defaults.baseURL = this.baseUrl;
    }

    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken(base_url)}`;

    return Vue.axios.get(resource).catch(error => {
      // console.log('error', error.response.status);
      if (error.response.status == 401) {
        let bootStrapToaster = new BToast();
        bootStrapToaster.$bvToast.toast("Silakan login kembali", {
          title: "Sesi telah berakhir",
          toaster: "b-toaster-top-right",
          variant: "danger",
          solid: true,
          appendToast: true
        });

        store.dispatch(LOGOUT);
        router.replace({ name: "login" });
      }

      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  async post(resource, params, base_url) {
    if (base_url == "elearning") {
      Vue.axios.defaults.baseURL = this.elearningUrl;
    } else if (base_url == "survey") {
      Vue.axios.defaults.baseURL = this.surveyUrl;
    } else if (base_url == "program") {
      Vue.axios.defaults.baseURL = this.programUrl;
    } else if (base_url == "elibrary") {
      Vue.axios.defaults.baseURL = this.elibraryUrl;
    } else if (base_url == "visualisasi") {
      Vue.axios.defaults.baseURL = this.visualisasiUrl;
    } else if (base_url == "sistemsehat") {
      Vue.axios.defaults.baseURL = this.sistemSehatUrl;
    } else {
      Vue.axios.defaults.baseURL = this.baseUrl;
    }

    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken(base_url)}`;

    return Vue.axios
      .post(`${resource}`, params)
      .then(data => {
        return data;
      })
      .catch(({ response }) => {
        let bootStrapToaster = new BToast();

        if (response?.data?.success && response?.data?.message) {
          bootStrapToaster.$bvToast.toast(response?.data?.message, {
            title: response?.data?.success ? "Berhasil" : "Error",
            toaster: "b-toaster-top-right",
            variant: response?.data?.success ? "success" : "danger",
            solid: true,
            appendToast: true
          });
        }

        if (response?.data?.message) {
          let errors =
            typeof response?.data?.errors == "string"
              ? [response?.data?.errors]
              : response?.data?.errors;

          for (var key in errors) {
            if (errors.hasOwnProperty(key)) {
              // console.log(key + " -> " + errors[key]);

              bootStrapToaster.$bvToast.toast(errors[key], {
                title: response?.data?.message,
                toaster: "b-toaster-top-right",
                variant: "danger",
                solid: true,
                appendToast: true
              });
            }
          }
        }

        return response;
      });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, base_url) {
    if (base_url == "elearning") {
      Vue.axios.defaults.baseURL = this.elearningUrl;
    } else if (base_url == "survey") {
      Vue.axios.defaults.baseURL = this.surveyUrl;
    } else if (base_url == "program") {
      Vue.axios.defaults.baseURL = this.programUrl;
    } else if (base_url == "elibrary") {
      Vue.axios.defaults.baseURL = this.elibraryUrl;
    } else if (base_url == "visualisasi") {
      Vue.axios.defaults.baseURL = this.visualisasiUrl;
    } else if (base_url == "sistemsehat") {
      Vue.axios.defaults.baseURL = this.sistemSehatUrl;
    } else {
      Vue.axios.defaults.baseURL = this.baseUrl;
    }
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken(base_url)}`;

    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params, base_url) {
    if (base_url == "elearning") {
      Vue.axios.defaults.baseURL = this.elearningUrl;
    } else if (base_url == "survey") {
      Vue.axios.defaults.baseURL = this.surveyUrl;
    } else if (base_url == "program") {
      Vue.axios.defaults.baseURL = this.programUrl;
    } else if (base_url == "elibrary") {
      Vue.axios.defaults.baseURL = this.elibraryUrl;
    } else if (base_url == "visualisasi") {
      Vue.axios.defaults.baseURL = this.visualisasiUrl;
    } else if (base_url == "sistemsehat") {
      Vue.axios.defaults.baseURL = this.sistemSehatUrl;
    } else {
      Vue.axios.defaults.baseURL = this.baseUrl;
    }
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken(base_url)}`;

    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, base_url) {
    if (base_url == "elearning") {
      Vue.axios.defaults.baseURL = this.elearningUrl;
    } else if (base_url == "survey") {
      Vue.axios.defaults.baseURL = this.surveyUrl;
    } else if (base_url == "program") {
      Vue.axios.defaults.baseURL = this.programUrl;
    } else if (base_url == "elibrary") {
      Vue.axios.defaults.baseURL = this.elibraryUrl;
    } else if (base_url == "visualisasi") {
      Vue.axios.defaults.baseURL = this.visualisasiUrl;
    } else if (base_url == "sistemsehat") {
      Vue.axios.defaults.baseURL = this.sistemSehatUrl;
    } else {
      Vue.axios.defaults.baseURL = this.baseUrl;
    }
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken(base_url)}`;

    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
