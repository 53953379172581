// USA
export const locale = {
  MENU: {
    HOME: "Beranda",
    AGENDA: "Agenda Kota",
    CITY: "Kata Kota",
    KSBB: "KSBB",
    MERCHANDISE: "Merchandise",
    PRIVACY: "Kebijakan Privasi",
    MEDIA: "Media",
    ABOUT: "Tentang Kami"
  },
  HOME: {
    TEMUKAN: "Temukan apa yang bisa kita lakukan",
    UNTUK: "untuk Jakarta yang lebih baik",
    BAHU:
      "Bahu membahu bantu saudara kita yang terdampak covid-19 langsung di lokasi yang membutuhkan.",
    LIHAT_KOLABORASI: "Lihat kolaborasi seru disekitarmu yang bisa kamu ikuti",
    SEMANGAT: "Semangat kota kolaborasi",
    MAJU: "maju kotanya bahagia warganya",
    LIHAT_KISAH: "Lihat kisah mereka yang telah berkolaborasi",
    APA_ITU_PLUSJAKARTA: "Apa itu ‘+Jakarta’?",
    PLUSJAKARTA_ADALAH:
      "(+)Jakarta adalah wadah penggerak semangat kolaborasi antara seluruh elemen kota dengan masyarakat dalam mewujudkan Jakarta yang maju kotanya dan bahagia warganya.",
    APA_ITU_KOLABORADI: "Apa itu ‘Kolaborasi’?",
    KOLABORASI_BERMAKNA:
      "Kolaborasi bermakna inisiasi yang memacu gagasan dan solusi bersama antar warga dengan kotanya sebagai langkah positif menyelesaikan masalah kota dan tantangan sosial.",
    SIAPA_DIBALIK: "Siapa dibalik +Jakarta?",
    AKUN_PLUSJAKARTA:
      "Akun +Jakarta dikelola oleh tim +Jakarta yang diprakarsai oleh Pemprov DKI Jakarta.",
    PUNYA_IDE: "Punya ide",
    KOLABORASI: "kolaborasi?",
    BERGABUNG: "Bergabung dan jadilah bagian dari perubahan Jakarta",
    MULAI: "Mulai Kolaborasi"
  },
  START_COLLABORATION: {
    NAMA_KAMU: "Nama kamu/instansi",
    pelajar: "pelajar",
    sineas: "sineas film",
    chef: "chef",
    guru: "guru",
    programmer: "programmer",
    desainer: "desainer",
    pemilik: "pemilik usaha",
    arsitek: "arsitek",
    musisi: "musisi",
    ibu: "ibu rumah tangga"
  },

  LIHAT_AGENDA: "Lihat Agenda",
  LIHAT_CARANYA: "Lihat caranya",
  LIHAT_CERITA: "Lihat Cerita",
  SELENGKAPNYA: "Selengkapnya",

  TRANSLATOR: {
    SELECT: "Select your language"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  }
};
