// USA
export const locale = {
  MENU: {
    HOME: "Home",
    AGENDA: "City Agenda",
    CITY: "Kata Kota",
    KSBB: "KSBB",
    MERCHANDISE: "Merchandise",
    PRIVACY: "Kebijakan Privasi",
    MEDIA: "Media",
    ABOUT: "About Us"
  },
  HOME: {
    TEMUKAN: "What we can do",
    UNTUK: "for a better Jakarta",
    BAHU:
      "Hand in hand to help our fellow citizens in areas directly affected by Covid-19",
    LIHAT_KOLABORASI: "Collaborations near you",
    SEMANGAT: "Spirit of Collaboration",
    MAJU: "Developed City, Happy Citizens",
    LIHAT_KISAH: "Previous Collaborators",
    APA_ITU_PLUSJAKARTA: "What is '+Jakarta'?",
    PLUSJAKARTA_ADALAH:
      "(+)Jakarta is a platform that aims to inspire the spirit of collaboration between all facets of the city and the community in realizing a developed Jakarta and happy citizens.",
    APA_ITU_KOLABORADI: "What is 'Collaboration'?",
    KOLABORASI_BERMAKNA:
      "Collaboration is the initiation that spurs joint ideas and solutions between citizens and their city as a positive step in solving urban problems and social challenges.",
    SIAPA_DIBALIK: "Who runs +Jakarta?",
    AKUN_PLUSJAKARTA:
      "The +Jakarta account is managed by the +Jakarta team initiated by the Jakarta Provincial Government.",
    PUNYA_IDE: "Have collaboration",
    KOLABORASI: "ideas?",
    BERGABUNG: "Join us and be a part of the change",
    MULAI: "Start Collaborating"
  },
  START_COLLABORATION: {
    NAMA_KAMU: "Your name/agency",
    pelajar: "student",
    sineas: "filmmaker",
    chef: "chef",
    guru: "teacher",
    programmer: "programmer",
    desainer: "designer",
    pemilik: "business owner",
    arsitek: "architect",
    musisi: "musician",
    ibu: "housewife"
  },

  LIHAT_AGENDA: "View Agenda",
  LIHAT_CARANYA: "Find out more",
  LIHAT_CERITA: "Our Story",
  SELENGKAPNYA: "Read More",

  TRANSLATOR: {
    SELECT: "Select your language"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  }
};
