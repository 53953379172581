import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/" : "./",
  routes: [
    {
      path: "/",
      redirect: "/akun-dan-role",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/akun-dan-role",
          name: "akun_dan_role",
          component: () => import("@/view/pages/AkunDanRole/index.vue")
        },
        {
          path: "/informasi-program",
          name: "informasi_program",
          component: () => import("@/view/pages/InformasiProgram/index.vue")
        },
        {
          path: "/informasi-program/detail/:id",
          name: "informasi_program_detail",
          props: true,
          component: () =>
            import("@/view/pages/InformasiProgramDetail/index.vue")
        },
        {
          path: "/aplikasi",
          name: "aplikasi",
          component: () => import("@/view/pages/Aplikasi/index.vue")
        },
        {
          path: "/visualisasi-data",
          name: "visualisasi_data",
          component: () => import("@/view/pages/VisualisasiData/index.vue")
        },
        {
          path: "/perpustakaan-online",
          name: "perpustakaan_online",
          component: () => import("@/view/pages/PerpustakaanOnline/index.vue")
        },
        {
          path: "/survey-online",
          name: "survey_online",
          component: () => import("@/view/pages/SurveyOnline/index.vue")
        },
        {
          path: "/survey-online/detail/:id_user/:id",
          name: "survey_online_detail",
          props: true,
          component: () => import("@/view/pages/SurveyOnlineDetail/index.vue")
        },
        {
          path: "/survey-online/detail/:id_user/:id/questioner",
          name: "survey_online_questioner",
          props: true,
          component: () =>
            import("@/view/pages/SurveyOnlineQuestioner/index.vue")
        },
        {
          path: "/peningkatan-kapasitas",
          name: "peningkatan_kapasitas",
          component: () => import("@/view/pages/PeningkatanKapasitas/index.vue")
        },
        {
          path: "/peningkatan-kapasitas/detail/:id",
          name: "peningkatan_kapasitas_detail",
          props: true,
          component: () =>
            import("@/view/pages/PeningkatanKapasitasDetail/index.vue")
        },
        {
          path: "/peningkatan-kapasitas/detail/:id/pertanyaan/:test_id",
          name: "peningkatan_kapasitas_test",
          props: true,
          component: () =>
            import("@/view/pages/PeningkatanKapasitasPertanyaan/index.vue")
        },
        {
          path: "/penilaian-sistem-sehat",
          name: "sistemsehat",
          component: () => import("@/view/pages/SistemSehat/index.vue")
        }

        // {
        //   path: "/builder",
        //   name: "builder",
        //   component: () => import("@/view/pages/Builder.vue")
        // }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/auth",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/auth/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/auth/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
